import {computed, onMounted, onUnmounted, ref} from "vue";

const additionalAppClass = ref(new Set);
export const addAdditionalAppClass = (val: string) => additionalAppClass.value.add(val);
export const removeAdditionalAppClass = (val: string) => additionalAppClass.value.delete(val);
export const getAdditionalAppClass = computed(() => [...additionalAppClass.value]);

export default function useAdditionalAppClass(classString: string) {
    onMounted(() => addAdditionalAppClass(classString));
    onUnmounted(() => removeAdditionalAppClass(classString));
}

import {captureException} from "@sentry/vue";

export function $toastMsg(msg) {
    const imports = [
        import("noty/src/noty.scss"),
        import("noty"),
        import("figma-squircle")
    ];

    Promise.all(imports).then(data => {
        const Noty = data[1].default;
        const isMob = document.body.offsetWidth < 767;
        let settings = {
            timeout: isMob ? 4000 : 10000,
            layout: "topRight",
            progressBar: false,
            theme: "songfinch",
            callbacks: {
                onShow(){
                    const target = this.barDom;
                    const svg = data[2].getSvgPath({
                        width: target.offsetWidth,
                        height: target.offsetHeight,
                        cornerRadius: 16, // defaults to 0
                        cornerSmoothing: 1, // cornerSmoothing goes from 0 to 1
                    });
                    target.innerHTML = `<div class="bodyWrapper">${target.innerHTML}</div>`;
                    target.querySelector(".noty_body").style.clipPath = `path('${svg}')`;

                }
            },
            closeWith: ["click", "button"]
        };
        if (typeof msg === "object") {
            settings = {...settings, ...msg};
        } else {
            settings.text = msg || "Success!";
        }
        new Noty(settings).show();
    }).catch(e => {
        alert(msg.text || msg);
        captureException(e);
    });
}

export default {
    install: function(app) {
        app.config.globalProperties.$toastMsg = $toastMsg;
    }
};

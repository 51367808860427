import VueSplide from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import Slider from "./Slider";

export default {
    install(app) {
        app.use(VueSplide);
        app.component("Slider", Slider);
    }
};

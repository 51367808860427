<template>
    <label
        class="mkCheckbox"
        :class="{disabled, radio, radioCheckStyle}"
        tabindex="0"
        role="checkbox"
        @keydown.space.enter.prevent="$refs.input?.click()"
    >
        <input
            ref="input"
            :type="type"
            :checked="modelValue === checkedVal"
            :disabled="disabled"
            :name="name"
            :required="required"
            tabindex="-1"
            @focus="$event.preventDefault()"
            @change="update"
        >
        <span class="checkboxIcon"/>
        <div v-if="text" class="p3" v-html="text"/>
        <slot v-else/>
    </label>
</template>

<script>
    export default {
        name: "MkCheckbox",
        props: {
            disabled: Boolean,
            modelValue: {
                type: [Number, String, Boolean],
                default: null
            },
            radio: Boolean,
            name: {
                type: String,
                default: ""
            },
            required: Boolean,
            text: {
                type: String,
                default: ""
            },
            checkedVal: {
                type: [Boolean, String, Number],
                default: true
            },
            uncheckedVal: {
                type: [Boolean, String, Number],
                default: false
            },
            radioCheckStyle: Boolean
        },
        emits: ["update:modelValue"],
        computed: {
            type() {
                return this.radio ? "radio" : "checkbox";
            }
        },
        created() {
            if (![this.checkedVal, this.uncheckedVal].includes(this.modelValue) && !this.radio) {
                this.$emit("update:modelValue", this.uncheckedVal);
            }
        },
        methods: {
            update(e) {
                if (!e.target.checked && this.radio) return;
                this.$emit("update:modelValue", e.target.checked ? this.checkedVal : this.uncheckedVal);
            }
        }
    };
</script>

let mainFunction;

export async function initUUID() {
    if (!mainFunction) {
        mainFunction = crypto?.randomUUID?.bind(crypto);
        if (!mainFunction) {
            const {v4} = await import("uuid");
            mainFunction = v4;
        }
    }
}

export default function uuid() {
    return mainFunction();
}

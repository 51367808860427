<template>
    <div v-if="data" style="display: none">
        <component
            :is="data.component"
            ref="modal"
            class="modalComponent"
            v-bind="data.props"
            v-on="data.events ?? {}"
            @click="$emit('modalClicked', $event)"
        />
    </div>
</template>

<script>
    import {addAdditionalAppClass, removeAdditionalAppClass} from "@songfinch/shared/composables/useAdditionalAppClass";
    import {merge, debounce} from "lodash-es";
    import modalParams from "./modalParams.ts";
    import modal from "./index";

    export default {
        name: "Modal",
        emits: ["modalClicked"],
        setup () {
            return {data: modalParams};
        },
        data() {
            return {
                breakpoints: null,
                lastBreakpoints: null,
                debounceFunc: null,
            };
        },
        watch: {
            data(val, old) {
                if (old?.appClass) removeAdditionalAppClass(old?.appClass);
                if (val?.appClass) addAdditionalAppClass(val?.appClass);
                if (val) {
                    this.$nextTick(() => {
                        let respParam;
                        window.removeEventListener("resize", this.debounceFunc);
                        if (val?.responsive) {
                            this.debounceFunc = debounce(this.resize, 500);
                            window.addEventListener("resize", this.debounceFunc);
                            this.breakpoints = Object.keys(val.responsive).sort(
                                (a, b) => a - b
                            );
                            this.lastBreakpoints = null;
                            respParam = this.findResponseParam();
                        } else {
                            this.breakpoints = null;
                        }
                        this.launchModal(merge({}, val, respParam));
                    });
                }
            },
        },
        methods: {
            slideSettingsHelper(direction) {
                const settings = {
                    "center-end": {in: "Right", out: "Right"},
                    "center-start": {in: "Left", out: "Left"},
                    top: {in: "Down", out: "Up"},
                    bottom: {in: "Up", out: "Down"},
                };
                const setting = settings[direction];
                return {
                    width: null,
                    position: direction,
                    showClass: {
                        popup: "slideIn" + setting.in,
                    },
                    hideClass: {
                        popup: "slideOut" + setting.out,
                    },
                    customClass: {
                        container: `@p-0 slide noPadding ${direction}`,
                    },
                };
            },
            findResponseParam() {
                if (!this.breakpoints) return;
                const size_index = this.breakpoints.find(
                    (br) => parseInt(br) >= window.innerWidth
                );
                if (this.lastBreakpoints !== size_index) {
                    this.lastBreakpoints = size_index;
                    return size_index ? this.data.responsive[size_index] : {};
                }
            },
            resize() {
                const respParam = this.findResponseParam();
                if (respParam)
                    this.launchModal(merge({}, this.data, respParam));
            },
            launchModal(val) {
                if (val?.slide) {
                    val.swalSettings = {
                        ...this.slideSettingsHelper(val.slide),
                        ...val.swalSettings,
                    };
                }
                modal.swal({
                    showConfirmButton: false,
                    showCloseButton: true,
                    customClass: {
                        container: "noPadding",
                    },
                    html: this.$refs.modal?.$el,
                    ...val.swalSettings,
                    willClose: () => {
                        modal.sfModal(null);
                        window.removeEventListener("resize", this.debounceFunc);
                        val.swalSettings?.afterClose?.();
                    },
                });
            },
        },
    };
</script>

<style lang="scss">
.swal2-container {
    z-index: var(--zix-modal) !important;

    .swal2-popup {
        animation-duration: 0.4s !important;
    }

    .swal2-icon {
        margin-top: 0;
    }

    .swal2-html-container {
        overflow: visible;
    }

    &.noPadding {
        .swal2-html-container {
            margin: 0 !important;
            border-radius: 3px;
            padding: 0;

            > * {
                width: 100%;
            }
        }

        .swal2-popup {
            padding: 0 !important;
        }
    }

    //Slide helper
    &.slide {
        &.top {
            padding-bottom: 20px !important;

            .swal2-popup {
                width: 100vw;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &.bottom {
            padding-top: 20px !important;

            .swal2-popup {
                width: 100vw;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.center-start {
            padding-right: 20px !important;

            .swal2-popup {
                min-height: 100vh;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &.center-end {
            padding-left: 20px !important;

            .swal2-popup {
                min-height: 100vh;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}
</style>
